.header-wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid black;
  background-color: #124e82;
  & .header-container {
    margin: auto;
    display: flex;
    align-items: center;
    padding: 5.5px;
    color: white;
    min-width: 480px;
    width: min(1440px, 100vw);
    & .header-button {
      margin: 0 10px 0 10px;
      text-decoration-line: none;
      color: white;
    }
    & :nth-child(2) {
      margin-left: auto;
    }
    & .user-name-text {
      line-height: 44px;
    }
    & .menu-line {
      color: white;
      height: 60px;
      line-height: 60px;
      margin-left: 20px;
    }
    & button,
    div {
      font-size: 12px;
      font-weight: 400;
    }
    // 메뉴
    .header-menu-warp {
      position: relative;
      > .menu-btn {
        width: 20px;
        margin-left: 4px;
      }
      > .menu-item-list {
        // width: 100px;
        height: 120px;
        padding: 16px;
        background-color: #fff;
        position: absolute;
        top: 50px;
        right: 0;
        z-index: 99999;
        border-radius: 8px;
        border: 1px solid #e5eaeb;
        box-shadow: 0px 3px 6px #e5e5e5;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        > .menu-item-user {
          color: #000;
        }
        > .menu-item-btn-list {
          margin-top: 32px;
          display: flex;
          gap: 8px;
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #e5e5e5;
            position: absolute;
            top: -16px;
          }
        }
        &.close {
          width: auto;
          max-height: 0;
          border: 0;
          padding: 0 16px;
          opacity: 0;
        }
        &.open {
          max-height: 120px;
          opacity: 1;
        }
        // 로그아웃 버튼
        & .header-button {
          margin: 0;
          color: #236daa;
          border: 1px solid #236daa;
          border-radius: 8px;
          width: 100px;
          &:hover {
            font-weight: 700;
            background-color: #236daa;
            color: #fff;
            border: 1px solid #236daa;
          }
        }
      }
    }
  }

  .menu-container {
    display: flex;
    padding: 10px;
    background-color: white;
    height: 72.5px;
    & button {
      background-color: white;
      min-width: 150px;
      z-index: 1000;
    }
    & button:hover {
      background-color: white;
    }
  }

  .header-logo {
    cursor: pointer;
  }
}
